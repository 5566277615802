import { Api } from './api';
import { getEventCategories } from './api-graphql';
import { getGeneralApiProblem } from './api-problem';
import { GetEventCategoriesResult } from './api.types';

export class EventCategoryApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  all(): Promise<GetEventCategoriesResult> {
    return this.api.mainAPIsauce.post('', getEventCategories()).then((response) => {
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const { categories } = response.data.data;
      return {
        kind: 'ok',
        categories
      };
    });
  }
}
