import React, { useState, useEffect } from 'react';
import tw, { styled } from 'twin.macro'; // eslint-disable-line import/no-extraneous-dependencies
import Layout from 'components/Layout';

import { EventCategoryApi } from 'services/api/event_category-api';
import { useApi } from 'providers/ApiProvider';
import Button from 'components/Button';
import EventList from 'components/EventList';
import Input from 'components/Input';
import Tabs from 'components/Tabs';
import Text from 'components/Text';
import Col from 'components/Col';
import Row from 'components/Row';
import { useAuth0 } from '@auth0/auth0-react';
import { useForm } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import { navigate } from 'gatsby';
import HomePageAnimation from 'components/HomePageAnimation';

import { useQuery } from '@apollo/client';
import { getEvents } from 'services/hasura';
import { CreateEventAction } from '../store/actions/CreateEventAction';

const Main = tw.div`
  text-center
`;

const Heading = tw.h1`
  text-7xl text-purple-900 font-semibold mt-2 pb-4
`;

const SignupContainer = tw.div`justify-center items-center space-x-2 w-full`;
const LocationContainer = tw.div`my-10 flex flex-row justify-center items-center space-x-2`;
const Footer = styled.footer`
  ${tw`flex`}
`;
const ListContainer = styled.div`
  ${tw`pt-10`}
`;
const HeaderText = styled(Text)`
  ${tw`my-8 w-5/12 md:w-full mx-auto`}
`;

const LocationText = styled(Text)`
  ${tw`border-2 border-purple-800 rounded-md px-3 py-1 hover:text-white hover:bg-purple-800 cursor-pointer`}
`;

const Index = () => {
  const [ival, setIval] = useState('');
  const { user, isLoading } = useAuth0();
  const { actions } = useStateMachine({ CreateEventAction });
  const { handleSubmit, ...methods } = useForm();
  const { api } = useApi();
  const [categories, setCategories] = useState([]);

  const eventCategoryAPI = new EventCategoryApi(api);
  useEffect(() => {
    const run = async () => {
      try {
        const result = await eventCategoryAPI.all();
        setCategories(result);
      } catch (error) {}
    };
    run();
  }, []);

  const resp = useQuery(getEvents(/* user?.sub */));

  //   useEffect(() => {
  //     resp.refetch();
  //   }, [isAuthenticated]);

  return (
    <Layout>
      {isLoading || resp.loading ? (
        <HomePageAnimation />
      ) : (
        <Main>
          <Heading>Tutadoo</Heading>
          <SignupContainer>
            {/* <Col>
            <HeaderText
              label={`Hey ${
                user?.given_name || user?.nickname || 'You'
              }! We know you want people to find the things they love to do.  Why don't you create an event`}
            />
          </Col> */}
            <Row spacing="sm" centered>
              <Input
                placeholder="Name of your event"
                secondary
                small
                onChange={(e) => setIval(e.target.value)}
              />
              <Button
                label="Create your Event"
                primary
                small
                onClick={() => {
                  actions.CreateEventAction({ name: ival });
                  navigate('/host/event/create/', { replace: true });
                }}
              />
            </Row>
            <Col spacing="sm">
              <Text label="contact us at hello@tutadoo.com for help." bold />
            </Col>
          </SignupContainer>
          <LocationContainer>
            <Text bold large label="What to do in" />
            <LocationText bold large accent label="Nairobi" />
          </LocationContainer>
          <Col spacing="sm">
            {categories && categories.length > 0 && (
              <Tabs items={categories.map(({ name }) => name)} pathname="/#" />
            )}
          </Col>
          <ListContainer>
            <EventList loading={resp.loading} events={resp.data?.events} />
          </ListContainer>
          <Footer />
        </Main>
      )}
    </Layout>
  );
};

export default Index;
