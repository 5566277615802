import React from 'react';
import EventCardAnimation from 'components/EventCardAnimation';
import tw from 'twin.macro';
import { Box } from 'grommet';

const Wrapper = tw.div`bg-gradient-to-b  grid place-items-center h-screen mx-24`;
const Inner = tw.div`bg-white p-4 rounded-md`;
const Pulse = tw.div`w-full h-44 bg-gray-200 animate-pulse`;
const Content = tw.div`mt-8 h-32 w-full space-y-3`;
const Item = tw.div`w-20 h-6 bg-gray-200 rounded-full animate-pulse`;

export default function HomePageAnimation() {
  return (
    <Wrapper>
      <Pulse />
      <Box direction="row" overflow="hidden">
        {['', '', '', '', '', '', '', '', '', '', '', ''].map(() => (
          <EventCardAnimation />
        ))}
      </Box>
    </Wrapper>
  );
}
